var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"score-container",class:{
    'red-score':
      _vm.evaluation_score.automated_score < 4 &&
      _vm.evaluation_score.automated_score >= 1,
    'yellow-score':
      _vm.evaluation_score.automated_score >= 4 &&
      _vm.evaluation_score.automated_score < 5.5,
    'green-score': _vm.evaluation_score.automated_score >= 5.5,
  }},[(_vm.loading)?[_vm._v("Cargando...")]:[(_vm.evaluation_score.automated_score)?[_vm._v(" "+_vm._s(_vm._f("Round")(_vm.evaluation_score.automated_score))+" ")]:[(_vm.allows_crud)?_c('b-form-input',{attrs:{"type":"number","min":"1","max":"7","size":"sm"},on:{"input":_vm.debouncepatchScore},model:{value:(_vm.evaluation_score.score),callback:function ($$v) {_vm.$set(_vm.evaluation_score, "score", $$v)},expression:"evaluation_score.score"}}):[_vm._v(_vm._s(_vm._f("Round")(_vm.evaluation_score.score)))]]]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }