<template>
  <div
    class="score-container"
    :class="{
      'red-score':
        evaluation_score.automated_score < 4 &&
        evaluation_score.automated_score >= 1,
      'yellow-score':
        evaluation_score.automated_score >= 4 &&
        evaluation_score.automated_score < 5.5,
      'green-score': evaluation_score.automated_score >= 5.5,
    }"
  >
    <template v-if="loading">Cargando...</template>
    <template v-else>
      <template v-if="evaluation_score.automated_score">
        {{ evaluation_score.automated_score | Round }}
      </template>
      <template v-else>
        <b-form-input
          v-if="allows_crud"
          type="number"
          v-model="evaluation_score.score"
          min="1"
          max="7"
          size="sm"
          @input="debouncepatchScore"
        ></b-form-input>
        <template v-else>{{ evaluation_score.score | Round }}</template>
      </template>
    </template>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
import { toast, generateUniqueId } from "@/utils/utils";

export default {
  name: "UserQualification",
  props: {
    user_id: {
      type: Number,
      required: true,
    },
    evaluation_id: {
      type: Number,
      required: true,
    },
    allows_crud: {
      type: Boolean,
      default: false,
    },
    evaluation_score_list: {
      type: Array,
    },
  },
  data() {
    return {
      evaluation_score: {
        id: generateUniqueId(),
        student: this.user_id,
        evaluation: this.evaluation_id,
        score: null,
        automated_score: null,
      },
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      evaluations: names.EVALUATIONS,
      questions: names.QUESTIONS,
      rubrics: names.OBSERVATION_INSTRUMENTS,
      users: names.USERS,
      rubricScores: names.OBSERVATION_SCORES,
    }),
    user() {
      return this.users.find((x) => x.id == this.user_id);
    },
    evaluation() {
      return this.evaluations.find((x) => x.id == this.evaluation_id);
    },
    rubric() {
      return this.rubrics.find((x) => x.evaluation == this.evaluation_id);
    },
    evaluationQuestions() {
      return this.questions.find((x) => x.evaluation == this.evaluation_id);
    },
    rubricScore() {
      if (!this.rubric) return null;
      return this.rubricScores.find(
        (x) => x.rubric == this.rubric.id && x.student == this.user_id
      );
    },
  },
  methods: {
    fetchEvaluationScore() {
      if (this.evaluation_score.score) return;
      if (this.evaluation_score_list) {
        if (
          this.evaluation_score_list.find(
            (x) =>
              x.student == this.user_id && x.evaluation == this.evaluation_id
          )
        ) {
          let score = (this.evaluation_score = this.evaluation_score_list.find(
            (x) =>
              x.student == this.user_id && x.evaluation == this.evaluation_id
          ));
          if (score.length == 1) this.evaluation_score = score[0];
          this.loading = false;
        }
      } else {
        this.loading = true;
        this.$restful
          .Get(
            `/evaluation/evaluation-score/?user=${this.user_id}&evaluation=${this.evaluation_id}`
          )
          .then((response) => {
            if (response.length == 1) this.evaluation_score = response[0];
            this.loading = false;
          });
      }
    },
    createScore() {
      if (this.evaluation_score.score < 1) this.evaluation_score.score = 1;
      else if (this.evaluation_score.score > 7) this.evaluation_score.score = 7;
      this.$restful
        .Post(`/evaluation/evaluation-score/`, this.evaluation_score)
        .then((response) => {
          this.evaluation_score = response;
          toast("Puntaje actualizado.");
        });
    },
    patchScore() {
      if (this.evaluation_score.score < 1) this.evaluation_score.score = 1;
      else if (this.evaluation_score.score > 7) this.evaluation_score.score = 7;
      this.$restful
        .Patch(`/evaluation/evaluation-score/${this.evaluation_score.id}/`, {
          score: this.evaluation_score.score,
        })
        .then((response) => {
          this.evaluation_score = response;
          toast("Puntaje actualizado.");
        });
    },
    debouncepatchScore() {
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        if (isNaN(this.evaluation_score.id)) this.createScore();
        else this.patchScore();
      }, 1000);
    },
  },
  watch: {
    evaluation_id() {
      this.evaluation_score.id = generateUniqueId();
      this.evaluation_score.student = this.user_id;
      this.evaluation_score.evaluation = this.evaluation_id;
      this.evaluation_score.score = null;
      this.evaluation_score.automated_score = null;
      this.fetchEvaluationScore();
    },
    evaluation_score_list(){
      this.fetchEvaluationScore();
    }
  },
  created() {
    this.fetchEvaluationScore();
  },
};
</script>

<style scoped>
.score-container {
  width: 100%;
  align-items: center;
}
.red-score {
  background-color: rgb(225, 109, 109);
}
.yellow-score {
  background-color: rgb(245, 227, 122);
}
.green-score {
  background-color: rgb(119, 225, 109);
}
</style>